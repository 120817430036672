html, body, section {
	width: 100%;
}

html, body {
	height: 100%;
	color: #333;
}

body {
	font-family: @font-family;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.5;
	height: 100%;
}

h1, h2, h3, h4 {
	text-align: center;
	line-height: 1.3;
	font-weight: normal;
}

h1 {
	margin: 0 0 40px;
	font-size: 40px;
}

h2 {
	margin: 0 0 30px;
	font-size: 35px;
}

h3 {
	margin: 0 0 30px;
	font-size: 30px;
}

h4 {
	margin: 0 0 30px;
	font-size: 25px;
}

table {
	width: 100%;
	td {
		vertical-align: top;
	}
}

hr {
	margin: 30px 0 40px;
	border-top: transparent;
	border-bottom: 1px solid #ccc;
}

a, button, input[type=submit] {
	transition: all .2s;
	outline: none !important;
	&:focus, &:active, &:hover {
		outline: none;
	}
}

.modal {
	.p {
		margin: 10px 0;
	}
	.btn {
		background: #f11 !important;
		box-shadow: none;
		border: solid 1px #000;
		color: #fff;
		&:hover {
			background-color: #f33 !important;
		}
	}
}

header, section, main {
	margin: 40px 0;
	padding: 0;
	position: relative;
	@media (min-width: @screen-md) {
		margin: 40px 0;
	}
	header, section, main {
		margin: 0;
		padding: 0;
	}
	&.section-bg {
		padding: 40px 1px;
		margin: 0;
		@media (min-width: @screen-md) {
			padding: 80px 0;
		}
	}
}

.btn-lg {
	.btn-lg();
}

.container, .row {
	.container {
		width: auto !important;
		padding-left: 0;
		padding-right: 0;
		margin-left: 0;
		margin-right: 0;
	}
}

.row .row {
	margin-left: 0;
	margin-right: 0;
}

.row-eq-height {
	display: flex;
	.block-wrapper {
		.box-shadow();
		padding: 30px;
		margin: 0 15px;
	}
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and ( min-device-pixel-ratio: 1.25), only screen and ( min-resolution: 200dpi), only screen and ( min-resolution: 1.25dppx) {
	-webkit-font-smoothing: subpixel-antialiased;
}