.section-screen {
	margin: 0;
	min-height: 650px;
	overflow: hidden;
	display: table;
	height: 100%;
	height: 100vh;
	.screen {
		&-cover {
			position: absolute;
			bottom: 0;
			top: 0;
			left: 0;
			right: 0;
			z-index: 2;
			width: 100%;
			height: 100%;
		}
		&-background {
			position: absolute;
			&-image {
				z-index: 0;
				bottom: 0;
				top: 0;
				left: 0;
				right: 0;
				text-align: center;
				background-position: center center;
				background-size: cover;
			}
			&-video {
				z-index: 1;
				top: -50%;
				left: -50%;
				width: 200%;
				height: 200%;
				video {
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					margin: auto;
					min-height: 50%;
					min-width: 50%;
					@media (max-width: @screen-xs-max) {
						display: none;
					}
				}
			}
		}
		&-content {
			color: #fff;
			position: relative;
			z-index: 3;
			display: table-cell;
			vertical-align: top;
			padding: 15px;
			padding-top: 170px;
			@media (max-width: @screen-sm-max) {
				padding-top: 0px;
			}
			> h1 {
				text-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-size: 45px;
				font-weight: bold;
				margin-top: 60px;
				margin-bottom: 20px;
				@media (max-width: @screen-sm-max) {
					font-size: 30px;
				}
			}
			> h2 {
				text-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
				color: #fff;
				font-size: 25px;
			}
			&-center {
				vertical-align: middle;
				padding-top: 15px;
			}
		}
		&-cta {
			margin: 60px auto 0;
			text-align: center;
			a {
				display: inline-block;
				font-size: 20px;
				padding: 15px 50px;
				@media (max-width: @screen-sm-max) {
					font-size: 18px;
					padding: 15px 20px;
				}
			}
			&-disclaimer {
				margin-top: 5px;
				font-size: 14px;
			}
		}
		&-icon {
			position: absolute;
			bottom: 30px;
			overflow: auto;
			height: 65px;
			left: 0;
			right: 0;
			img {
				display: block;
				margin: 0 auto;
				animation: scrollAnimation 2s infinite;
				animation-timing-function: ease-in-out;
			}
			@keyframes scrollAnimation {
				50% {
					margin-top: 15px;
				}
			}
		}
	}
}
