.section-header {
	margin: 0;
	z-index: 1010;
	width: 100%;
	margin: 25px 0;
	color: #000;
	background-color: rgba(0, 0, 0, 0);
	transition: all .2s ease-in-out;
	@media (max-width: @screen-sm-max) {
		margin-top: 70px;
		text-align: center;
	}
	&-absolute {
		position: absolute;
		color: #fff;
		a {
			color: inherit;
		}
	}
	&-fixed {
		padding: 10px 0;
		background-color: rgba(0, 0, 0, .5);
		position: fixed;
		margin: 0;
		top: 0;
		color: #fff;
		a {
			color: inherit;
			i {
				color: #fff;
			}
		}
		.header {
			&-logo {
				display: none;
				img {
					max-height: 90px;
					@media (max-width: @screen-sm-max) {
						display: none;
					}
				}
			}
			&-tagline {
				display: none;
			}
			&-company {
				display: none;
				&-title {
					margin-top: 15px;
				}
			}
			&-contacts-phone {
				font-size: 20px;
			}
			&-contacts-disclaimer {
				@media (max-width: @screen-sm-max) {
					display: none;
				}
			}
			&-navbar {
				margin-top: 10px !important;
			}
		}
	}

	a {
		color: inherit;
	}

	.header {
		&-navbar {
			border: none;
			position: fixed;
			@media (min-width: @screen-md) {
				position: relative;
				margin: 20px 0 0;
				background-color: transparent;
			}
			.navbar-brand {
				color: #fff;
			}
			li > a {
				&:hover {
					background-color: darken(@color-primary, 5%);
				}
				i {
					font-style: normal;
					display: inline-block;
					&:after {
						content: '';
						display: block;
						border-bottom: solid 1px #fff;
					}
				}
			}
			.active > a {
				background-color: darken(@color-primary, 10%) !important;
			}
			.dropdown-toggle {
				&:hover {
					background-color: darken(@color-primary, 5%) !important;
				}
			}
			.dropdown-menu {
				.active > a {
					color: #fff;
					background-color: darken(@color-primary, 10%) !important;
				}
				a {
					color: @color-primary;
					padding: 10px 15px;
					&:hover {
						background-color: lighten(@color-primary, 50%) !important;
					}
					i {
						&:after {
							display: none;
						}
					}
				}
			}
			.navbar-brand {
				display: none;
			}
			&-fixed-top {
				position: fixed;
				margin: 0;
				top: 0;
				.navbar-brand {
					display: block;
				}
			}
		}
		&-wrapper {
			display: table;
			width: 100%;
		}
		&-logo, &-tagline {
		}
		&-logo {
			vertical-align: top;
			padding-right: 25px;
			display: table-cell;
			@media (max-width: @screen-sm-max) {
				display: block;
				padding: 0;
			}
			img {
				max-height: 90px;
				@media (max-width: @screen-sm-max) {
					max-width: 90px;
				}
			}
		}
		&-company {
			display: table-cell;
			white-space: nowrap;
			vertical-align: top;
			@media (max-width: @screen-sm-max) {
				text-align: center;
				display: block;
			}
			&-title {
				font-weight: bold;
				font-size: 20px;
				margin-bottom: 5px;
			}
		}
		&-tagline {
		}
		&-contacts {
			display: table-cell;
			width: 100%;
			text-align: right;
			font-size: 14px;
			@media (max-width: @screen-sm-max) {
				display: block;
				text-align: center;
			}
			&-phone {
				font-weight: bold;
				font-size: 30px;
			}
			&-disclaimer {
			}
			&-callback {
				display: none;
				background: none;
				border: none;
				border-bottom: dashed 1px;
				padding: 0;
				&:hover {
					color: @color-primary;
				}
			}
		}
	}
}

.section-footer {
	margin: 40px 0;
	.footer {
		&-company {
			float: left;
			font-size: 20px;
			@media (max-width: @screen-sm-max) {
				text-align: center;
				float: none;
			}
			&-phone {
				font-size: 30px;
				@media (max-width: @screen-sm-max) {
					font-size: 20px;
				}
			}
		}
		&-top {
			text-align: center;
			.btn-outline {
				.btn-lg();
				display: inline-block;
			}
		}
		&-perfecto {
			float: right;
			margin-top: -5px;
			@media (max-width: @screen-sm-max) {
				text-align: center;
				margin: 20px 0;
				float: none;
			}
		}
	}
}

.breadcrumbs {
	.list-inline();
	font-size: 12px;
	color: #999;
	margin: 5px 0 25px;
	li:first-child {
		padding-left: 0;
	}
	a {
		color: inherit;
	}
}