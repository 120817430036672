.modal-ouibounce {
	.modal {
		&-content .close {
		}
		&-dialog {
			width: 750px;
			.capture-inline {
				background: none;
				padding: 0 10px;
				.capture-img img {
					max-width: 250px;
				}
				.capture-description-content {
					color: #000;
				}
				.capture-disclaimer {
					color: #000;
					font-size: 14px;
				}
				.form-group {
					display: block;
					width: 100%;
					margin-bottom: 5px;
				}
			}
		}
	}
}
