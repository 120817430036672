.section-text {
	.text {
		&-content {
			font-size: 16px;
			margin: 40px 0;
		}
	}
	.gallery {
		&-thumbnails {
			margin: 30px 0 40px;
			.clearfix();
			&.nospacing {
				.gallery-thumbnails-item {
					padding: 0;
				}
			}
			&-item {
				list-style: none;
				padding: 5px;
			}
			img {
				margin-left: auto;
				margin-right: auto;
			}
			&-title {
				display: block;
				text-align: center;
				color: @color-secondary;
				margin: 10px 0;
				font-size: 20px;
			}
			&-description {
				display: block;
				text-align: left;
				font-size: 16px;
				margin: 10px 0;
			}
		}
		&-slider {
			height: 450px;
			overflow: hidden;
			.gallery-slide {
				height: 450px;
				background-size: cover;
				display: table;
				width: 100%;
				&-caption {
					display: table-cell;
					vertical-align: middle;
					text-align: center;
					a {
						text-decoration: none;
					}
				}
				&-title, &-description {
					color: #fff;
					text-shadow: 0 1px 4px rgba(0, 0, 0, .8);
				}
				&-title {
					font-size: 40px;
					font-weight: bold;
				}
				&-description {
					font-size: 25px;
				}
			}
		}
		&-collage {
			.collage {
				@cell-height: 300px;
				@cell-margin: 20px;
				&-cell {
					background-color: #999;
					background-size: cover;
					background-position: center center;
					margin-bottom: @cell-margin;
					display: table;
					width: 100%;
					&-12 {
						height: @cell-height;
						&-ext {
							height: (@cell-height + @cell-margin);
						}
					}
					&-14 {
						height: (@cell-height / 2);
					}
					a {
						transition: color .25s ease-out, background-color .25s ease-out, border-color .25s ease-out, box-shadow .25s ease-out;
						background-color: rgba(0,0,0,.3);
						text-decoration: none;
						display: table-cell;
						vertical-align: bottom;
						padding-bottom: 10px;
						.collage-item-caption {
							display: block;
						}
						color: inherit;
						&:hover {
							background-color: rgba(0,0,0,.5);
							.collage-item-caption {
								opacity: 1;
							}
						}
					}
					.collage-item {
						&-caption {
							display: table-cell;
							opacity: .7;
							color: #fff;
							text-shadow: 0px 1px 1px rgba(0, 0, 0, .5);
							text-align: center;
							vertical-align: middle;
							font-weight: 400;
							font-size: 18px;
						}
						&-description {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	.slick {
		&-arrow {
			z-index: 999;
			position: absolute;
			top: 0;
			bottom: 0;
			padding: 0 20px;
			background: none;
			border: none;
			font-size: 30px;
		}
		&-prev {
			left: 0;
		}
		&-next {
			right: 0;
		}
		&-active {
			button {
				background: @color-secondary;
			}
		}
		&-dots {
			.list-inline();
			margin-top: 30px;
			text-align: center;
			button {
				font-size: 0;
				line-height: 0;
				border: 0;
				display: block;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				padding: 5px;
				cursor: pointer;
				outline: none;
				background: #ccc;
			}
		}
	}
}