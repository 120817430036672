.section-thank-you {
	display: table;
	width: 100%;
	height: 100%;
	margin: 0;
	.thank-you-center {
		display: table-cell;
		text-align: center;
		vertical-align: middle;
		h1 {
			margin: 0;
		}
		a {
			margin: 40px 0;
			display: block;
			font-size: 18px;
		}
	}
}
