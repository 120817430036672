.section-features {
	h2 {
		text-align: center;
	}
	.features {
		&-content {
			font-size: 24px;
			text-align: center;
			margin: 30px 0 40px;
			@media (max-width: @screen-sm-max) {
				font-size: 20px;
			}
		}
		&-row {
			margin-top: 40px;
			@media (max-width: @screen-sm-max) {
				margin-top: 0 !important;
			}
			&-first {
				margin-top: 0 !important;
			}
		}
		&-lg {
			.features-row {
				margin-top: 60px;
			}
		}
		&-md {
			.features-row {
				margin-top: 40px;
			}
		}
		&-sm {
			.features-row {
				margin-top: 20px;
			}
		}
		&-left, &-right {
			.feature {
				&-img {
					&-cell {
						display: table-cell;
						vertical-align: top;
						@media (max-width: @screen-sm-max) {
							display: block;
							text-align: center;
						}
					}
					&-wrapper {
						display: block;
					}
				}
				&-description {
					display: table-cell;
					vertical-align: middle;
				}
			}
		}
		&-top, &-bottom {
			.feature {
				&-img {
					&-cell {
						display: table;
						width: 100%;
						margin: 20px auto;
					}
					&-wrapper {
						display: table-cell;
						vertical-align: middle;
						margin: 0 auto 20px;
					}
				}
			}
		}
		&-left {
			text-align: left;
			.feature-img {
				&-sm {
					margin-right: 20px;
				}
				&-md, &-lg {
					margin-right: 40px;
				}
				&-wrapper {
					@media (max-width: @screen-sm-max) {
						margin: 40px auto 20px;
					}
				}
			}
			.feature-img, .feature-description {
				text-align: left;
				h1, h2, h3, h4 {
					text-align: left;
				}
				.capture-modal-button-wrapper {
					text-align: left;
				}
			}
		}
		&-right {
			text-align: right;
			.feature-img {
				&-sm {
					padding-left: 20px;
				}
				&-md, &-lg {
					padding-left: 40px;
				}
				&-wrapper {
					@media (max-width: @screen-sm-max) {
						margin: 20px auto 40px;
					}
				}
			}
			.feature-description {
				text-align: right;
				h1, h2, h3, h4 {
					text-align: right;
				}
			}
		}
	}

	.feature {
		&-img {
			&-wrapper {
				.highlight-bg {
					background: @color-primary;
				}
				display: table-cell;
				vertical-align: middle;
				text-align: center;
				white-space: nowrap;
				img {
					&.img-padding {
						padding: 20px;
					}
				}
			}
			&-txt {
				display: block;
				width: 100%;
				padding-bottom: 100%;
				position: relative;
				strong {
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					text-align: center;
				}
				&.txt-circle {
					border-radius: 50%;
					max-width: 100%;
					max-height: 100%;
				}
				&.txt-square {
					max-width: 100%;
					max-height: 100%;
				}
			}
			&-sm {
				font-size: 14px;
				width: 64px;
				height: 64px;
				.feature-img-txt, img {
					max-width: 64px;
					max-height: 64px;
				}
				img {
					&.img-padding {
						padding: 5px;
					}
				}
				.feature-img-txt {
					width: 32px;
					height: 32px;
					padding-bottom: 0;
					margin: 0 auto;
				}
				strong {
					margin-top: -10px;
				}
			}
			&-md {
				font-size: 40px;
				font-weight: bold;
				width: 150px;
				height: 150px;
				.feature-img-txt, img {
					max-width: 150px;
					max-height: 150px;
				}
				strong {
					margin-top: -30px;
				}
			}
			&-lg {
				font-size: 50px;
				font-weight: bold;
				width: 300px;
				height: 300px;
				.feature-img-txt, img {
					max-width: 300px;
					max-height: 300px;
				}
				strong {
					margin-top: -35px;
				}
			}
		}
		&-description {
			width: 100%;
			text-align: center;
			@media (min-width: @screen-sm) {
				font-size: 20px;
			}
			@media (max-width: @screen-sm-max) {
				float: none;
				display: block;
			}
			h1, h2, h3, h4 {
				margin-bottom: 10px;
				color: @color-secondary;
				a {
					color: inherit;
				}
				@media (max-width: @screen-sm-max) {
					text-align: center !important;
				}
			}
			strong {
				font-weight: normal;
				padding: 1px 5px;
				background: fade(@color-primary, 30%);
			}
			.section-capture {
				margin: 20px 0 0;
				.capture {
					&-modal-button {
						.btn-md();
						white-space: normal;
						background: transparent;
						&-disclaimer {
							font-size: 14px;
							margin-top: 5px;
						}
					}
				}
			}
		}
	}

}
