.section-wrapper {
	&-fullwidth {
	}
	&-multicolumn {
		h1, h2, h3, h4 {
			text-align: left;
		}
		&-first {
			text-align: center;
			h1, h2, h3, h4 {
				text-align: center;
			}
		}
	}
	.wrapper {
		&-overlay {
			z-index: 1;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		&-container {
			position: relative;
			z-index: 2;
			.row {
//				@media (min-width: @screen-md) {
//					display: table;
//					height: 100%;
//				}
//				*[class*="col-"] {
//					display: table-cell;
//					vertical-align: top;
//					float: none;
//					height: 100%;
//				}
				section:first-child {
//					margin-top: 0;
//					height: 100%;
				}
			}
			//			*[class*="col-"] { }
		}
	}
}