.section-capture {
	margin: 40px 0;
	.capture {
		&-files {
			ul {
				.list-unstyled();
			}
			li {
				height: 45px;
			}
			.file {
				&-icon {
					border-bottom: none;
					text-decoration: underline;
					&:before {
						vertical-align: middle;
						content: '';
						display: inline-block;
						width: 32px;
						height: 32px;
						margin-right: 10px;
						background: center center no-repeat;
						background-size: 32px 32px;
						background-image: url(/assets/website/dashboard/default/images/icons/files/file.png);
					}
				}
				&-pdf:before {
					background-image: url(/assets/website/dashboard/default/images/icons/files/pdf.png);
				}
				&-xls:before, &-xlsx:before {
					background-image: url(/assets/website/dashboard/default/images/icons/files/excel.png);
				}
				&-ppt:before, &-pptx:before {
					background-image: url(/assets/website/dashboard/default/images/icons/files/powerpoint.png);
				}
				&-doc:before, &-docx:before {
					background-image: url(/assets/website/dashboard/default/images/icons/files/word.png);
				}
			}
		}
		&-inline {
			text-align: center;
			display: table;
			width: 100%;
			max-width: 960px;
			margin: 30px auto;
			padding: 30px;
			background: rgba(0, 0, 0, .3);
		}
		&-modal-button {
			.btn-lg();
			white-space: normal;
			background: transparent;
			display: inline-block;
			margin-bottom: 0;
			&-wrapper {
				text-align: center;
			}
			&-disclaimer {
				margin-top: 10px;
			}
		}
		&-description {
			&-content {
				text-align: left;
				color: #fff;
				margin-bottom: 20px;
			}
		}
		&-img {
			&-left, &-right {
				display: table-cell;
			}
			&-left {
				padding-right: 30px;
			}
			&-right {
				padding-left: 30px;
			}
			img {
				max-width: 300px;
				max-height: 400px;
			}
			@media (max-width: @screen-sm-max) {
				display: none;
			}
		}
		&-form {
			vertical-align: top;
			display: table-cell;
			width: 100%;
			h2 {
				font-size: 25px;
				text-align: left;
				color: @color-secondary;
				margin-bottom: 15px;
			}
			.row {
				margin: 0 -5px;
				.form-group {
					padding: 5px;
				}
			}
			input, textarea {
				border-radius: 5px;
				font-size: 16px;
				padding: 0 15px;
				height: 50px;
			}
			textarea {
				padding: 15px;
				height: 100px;
			}
			input[type="submit"] {
				.text-shadow();
				white-space: normal;
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3), inset 0 -5px 0 rgba(0, 0, 0, 0.25);
				color: #fff;
				font-size: 25px;
				min-height: 60px;
				height: auto;
				background: @color-secondary;
				border-color: darken(@color-secondary, 50%);
				border-radius: 15px;
				padding: 0;
				margin: 5px 0 0;
				box-sizing: border-box;
				transition: box-shadow .2s ease-in-out;
				@media (max-width: @screen-sm-max) {
					font-size: 20px;
				}
				&:hover {
					background: darken(@color-secondary, 5%);
				}
				&:active {
					box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3), inset 0 -3px 0 rgba(0, 0, 0, 0.25);
				}
			}
		}
		&-disclaimer {
			color: #fff;
		}
	}
}

.modal {
	.btn {
		background: #CA0202;
		background: linear-gradient(to top, #d11414 0%, #d11414 0%, #ee2924 100%);
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
		border: none;
		outline: none;
		height: 40px;
		min-width: 340px;
		font-size: 18px;
		box-sizing: border-box;
		padding: 3px 12px 0;
		&:hover {
			background: linear-gradient(to top, #d11414 0%, #d11414 0%, #ee2924 70%);
		}
		&:active {
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
		}
	}
}
