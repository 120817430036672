.box-shadow() {
	box-shadow: rgba(0,0,0,.4) 0 25px 40px -15px;
}
.text-shadow() {
	text-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px;
}
.btn() {
	display: inline-block;
	background: #fff;
	color: @color-secondary;
	transition: color, background .2s;
	&:hover {
		text-decoration: none;
		background: @color-secondary;
		color: #fff !important;
	}

}
.btn-md() {
	.btn();
	padding: 10px 40px;
	border-radius: 10px;
	border: 2px solid @color-secondary;
	font-size: 18px;
}
.btn-lg() {
	.btn();
	padding: 15px 50px;
	border-radius: 25px;
	border: 3px solid @color-secondary;
	font-size: 20px;
}

.btn-cta {
	background: #e53e36;
	background: linear-gradient(#e94b43, #e03029);
	display: inline-block;
	border: 1px solid #ba0404;
	color: #fff !important;
	line-height: normal;
	font-size: 24px;
	padding: 10px 60px;
	box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.75), inset 0 -3px #ab0303;
	text-shadow: 0 -1px rgba(0, 0, 0, 0.3);
	&:hover {
		text-decoration: none;
		background: #e7534d;
		background: linear-gradient(#ec615a, #e3463f);
	}
	&:active {
		position: relative;
		margin-bottom: 2px;
		box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.75), inset 0 -1px #ab0303;
	}
}

.preloader {
	width: 32px;
	height: 32px;
	background: url(/assets/website/app/default/images/preloaders/1.gif);
	background-size: 100% 100%;
}
